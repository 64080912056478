<template>
    <div class="editor-test">
        <div ref="editorRef" class="test"></div>
    </div>
</template>

<style lang="less" scoped>
.editor-test {
    padding: 10px;

    .test {
        width: 600px;
        height: 300px;
        background-color: #fff;
        overflow-y: auto;
        resize: vertical;
    }
}
</style>

<script setup>
import { ref, onMounted } from 'vue';
import { basicSetup, EditorView } from 'codemirror';
import { autocompletion } from '@codemirror/autocomplete';
// import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { linter, lintGutter } from '@codemirror/lint';
import { tags } from '@lezer/highlight';

let queryLabelRegex = /\{[0-9]*,[0-9]*\}/g;

// Your custom lint function
function customLint(view) {
    let matchesLabel = [];
    let prevLength = 0;
    view.state.doc.text.forEach(txt => {
        let matches = txt.match(queryLabelRegex);
        if (!matches) {
            return;
        }
        matches.forEach(mtxt => {
            let idxOf = txt.indexOf(mtxt);
            matchesLabel.push({
                label: mtxt,
                from: idxOf + prevLength,
                to: idxOf + mtxt.length + prevLength,
            });
        });
        prevLength += txt.length + 1;
    });
    return matchesLabel.map(item => {
        return {
            from: item.from,
            to: item.to,
            severity: 'info',
            message: queryCompletionList.find(
                query => query.apply + '}' === item.label
            ).label,
        };
    });
}

let editorRef = ref();

let nervCompletionMap = [
    {
        label: 'sum',
        type: 'function',
        apply: 'sum()',
        detail: '将「对象」中所有的“线”进行加总',
    },
    {
        label: 'mean',
        type: 'function',
        apply: 'mean()',
        detail: '将「对象」中所有的“线”进行平均',
    },
    {
        label: 'combine',
        type: 'function',
        apply: 'combine()',
        detail: '将多个「对象」组装成一个对象',
    },
    {
        label: 'merge',
        type: 'function',
        apply: 'merge()',
        detail: '将多个「对象」合并成一个对象',
    },
    {
        label: 'shift',
        type: 'function',
        apply: 'shift(periods,freq)',
        detail: '将对象在时间轴上向右平移',
    },
    {
        label: 'rolling',
        type: 'function',
        apply: 'rolling(window)',
        detail: '平滑曲线',
    },
    {
        label: 'resample',
        type: 'function',
        apply: 'resample(rule,fun)',
        detail: '重新采样',
    },
    {
        label: 'rename',
        type: 'function',
        apply: 'rename(new_name)',
        detail: '重命名',
    },
    {
        label: 'attr',
        type: 'function',
        apply: 'attr(key,value)',
        detail: '追加属性',
    },
];

let queryCompletionList = [];

function nervCompletions(context) {
    let queryMatch = context.matchBefore(/\{/);
    let dotMatch = context.matchBefore(/\./);
    let before = context.matchBefore(/\w+/);

    if (!context.explicit && !queryMatch && !dotMatch && !before) {
        return null;
    }

    if (queryMatch && queryMatch.text === '{') {
        return {
            from: queryMatch ? queryMatch.from : context.pos,
            options: queryCompletionList,
            validFor: /\{[\w\s:_]+/,
        };
    }

    if (dotMatch && dotMatch.text === '.') {
        return {
            from: context.pos,
            options: nervCompletionMap,
            validFor: /\.[\w]+/,
        };
    }

    return {
        from: before ? before.from : context.pos,
        options: nervCompletionMap,
        validFor: /^\w*$/,
    };
}

// let nervHighlightStyle = HighlightStyle.define([
//     { tag: tags.variableName, color: '#ff0' },
//     { tag: tags.bracket, color: '#f00' },
// ]);

let editorDoc = ref('');

onMounted(() => {
    let b = linter(customLint);

    console.log(b);

    new EditorView({
        doc: editorDoc.value,
        extensions: [
            basicSetup,
            autocompletion({ override: [nervCompletions] }),
            EditorView.updateListener.of(v => {
                if (v.docChanged) {
                    editorDoc.value = v.state.doc.toString();
                }
            }),
            lintGutter(),
            b,
        ],
        parent: editorRef.value,
    });

    queryCompletionList = [
        {
            label: '{36:热云工程成本Query}',
            type: 'variable',
            apply: '{10,36',
        },
        {
            label: '{35:FinOps Biz Query}',
            type: 'variable',
            apply: '{10,35',
        },
        {
            label: '{31:大数据成本}',
            type: 'variable',
            apply: '{10,31',
        },
        {
            label: '{28:工程广告推荐成本Query}',
            type: 'variable',
            apply: '{10,28',
        },
    ];
});
</script>
